import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import {
  CAMPAIGNS,
  DISTANCELEARNING,
  DYPATILUNIVERSITY,
  EARLYSALARY,
  LOGIN,
} from "./routes";
const EarlySalary = React.lazy(() => import("./Pages/EarlySalary"));
const Login = React.lazy(() => import("./Pages/EarlySalary/Login"));
const EarlySalaryData = React.lazy(() =>
  import("./Pages/Campaigns/EarlySalaryData")
);
const Campaigns = React.lazy(() => import("./Pages/Campaigns"));
const DyPatilUnidata = React.lazy(() =>
  import("./Pages/Campaigns/DyPatilUniData")
);
const DistanceLearning = React.lazy(() =>
  import("./Pages/Campaigns/DistanceLearning")
);
const Routes = (props) => {
  return (
    <Switch>
      <Route path={LOGIN} element={<Login />} />
      <Route path={CAMPAIGNS} element={<Campaigns />} />

      <Route
        path={`${CAMPAIGNS}${EARLYSALARY}`}
        element={<EarlySalaryData />}
      />
      <Route
        path={`${CAMPAIGNS}${DYPATILUNIVERSITY}`}
        element={<DyPatilUnidata />}
      />
      <Route
        path={`${CAMPAIGNS}${DISTANCELEARNING}`}
        element={<DistanceLearning />}
      />

      <Route path={EARLYSALARY} element={<EarlySalary />} />
    </Switch>
  );
};

export default Routes;
