import { BrowserRouter as Router } from "react-router-dom";
import React from "react";
import Routes from "./appRoutes";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Router>
        <Routes />
      </Router>
    </LocalizationProvider>
  );
}

export default App;
